import React from "react";
import './welcome.css'

const Welcome = () => {
  return (
    <div className="w-100">
      <div className="welcomeContainer w-100">
        <h2 className="welcomeTitle">Welcome!</h2>
      </div>
      <div className="mainText mt-3 row p-5 font-weight-bold">
        <div className="p-2 col-sm-12 col-md-4 text-center">
          <p className="teachingContainer ">Teaching CPR and First Aid Classes in <strong>English</strong> and <strong>Hmong</strong></p>
        </div>
        <div className="col-sm-12 col-md-8 text-left infoContainer">
          <p className="">
            Train 2 Sustain is your on-site American Heart Association (AHA) and HSI (Health and Safety Institute) CPR and First Aid educator, offering:
          </p>
          <br />
          <p>
            <strong>* BLS (Basic Life Support) for Healthcare Providers </strong>(equipping you with healthcare provider-level CPR certification that can be used in hospitals, clinics and for educational programs in medical fields)
          </p>
          <br />
          <p>
            <strong>* CPR AED</strong> (providing you with basic hands-on training in CPR and how to use an AED - automatic external defibrillator)
          </p>
          <br />
          <p>
            <strong>* First Aid</strong> (enabling you to respond to a variety of home and workplace emergencies, including how to care for persons suffering from allergic reactions, asthma attacks, seizures, diabetic emergencies, heart attack and stroke)
          </p>
          <br />
          <p>
            <strong></strong>At Train 2 Sustain, we have a passion for saving lives! If you wish to schedule a corporate or group CPR class, please contact us at:
          </p>
          <br />
          <a href="tel:916-581-0012" className="telephoneAnchor" >Phone: 916-581-0012</a>
          <br />
          <p className="mt-1" onClick={() => window.location = 'mailto:train2sustain@gmail.com'}>E-mail: train2sustain@gmail.com</p>
        </div>
      </div >
    </div >
  )
}
export default Welcome;