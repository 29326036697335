import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MyNavBar from './lib/navbar/MyNavBar.js';
import Welcome from './lib/welcome/Welcome.js';
import About from './lib/about/About.js';
import Faq from './lib/faq/Faq.js';
import Resources from './lib/resources/Resources.js';
import Footer from './lib/footer/Footer.js';
import './App.css';

function App() {
  return (
    <div className='App'>
      <MyNavBar />
      <div className='mt-2'> 
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/resources" element={<Resources />} />
        </Routes>
      </div>
      <Footer />
    </div>

  );
}

export default App;
