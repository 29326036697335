import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="w-100 aboutUsContiner">
      <div className="aboutContainer w-100 ">
        <h2 className="welcomeTitle ">About Us</h2>
      </div>
      <div className="mt-3 row p-5 font-weight-bold">
        <p>
          Train 2 Sustain, LLC is an American Heart Association certified life
          support education provider, teaching CPR courses in both English and
          Hmong. We specialize in training individuals and groups in:
        </p>
        <div className="pr-2 pl-2 listContainer">
          <ul>
            <li>BLS (Basic Life Support) for Healthcare Providers</li>
            <li>
              CPR (Cardiopulmonary Resuscitation) for Adults, Children and
              Infants
            </li>
            <li>Proper use of AEDs (Automated External Defibrillators) </li>
            <li>
              First Aid (The AHA first responder program) equipping individuals
              to effectively deal with an array of workplace and home
              emergencies
            </li>
          </ul>
        </div>
        <div className="font-weight-bold">
          <p>
            We understand the needs of busy professionals who require continuing
            life support education and re-certification, in order to provide
            urgent, competent care to their patients and communities. We also
            recognize the desire that parents have to be equipped to perform CPR
            to aid their newborns, infants and children, and that of caregivers
            to respond to the medical needs of their loved ones. To that end, we
            offer tailored training to fit your specific needs.
          </p>
          <p>
            We offer training on-site for private groups and organizations'
            staff. Please contact us for discounted rates for group classes.
          </p>
          <p>
            Please contact us at{" "}
            <a href="tel:916-581-0012" className="telephoneAnchor">
              916-581-0012{" "}
            </a>
            or at&nbsp;
            <span
              className="emailSpan"
              onClick={() =>
                (window.location = "mailto:train2sustain@gmail.com")
              }
            >
              train2sustain@gmail.com
            </span>
            &nbsp; to make a reservation for your first class.
          </p>
        </div>
        <div className=" d-flex justify-content-around align-items-center">
          <h3>Contact Us</h3>
          <h6>
            Phone:{" "}
            <a href="tel:916-581-0012" className="telephoneAnchor">
              916-581-0012
            </a>
          </h6>
          <h6>
            Email:{" "}
            <span
              className="emailSpan"
              onClick={() =>
                (window.location = "mailto:train2sustain@gmail.com")
              }
            >
              train2sustain@gmail.com
            </span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default About;
