import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div class="top p-2">
        <div class="newsletter">
          <div class="formContainer mx-auto">
            <h1>Contact Us</h1>
            <form
              target="_blank"
              action="https://formsubmit.co/train2sustain@gmail.com"
              method="POST"
            >
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="text"
                      name="employerName"
                      class="form-control"
                      placeholder="Employer Name"
                    />
                  </div>
                  <div class="col-12">
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea
                  placeholder="Your Message"
                  class="form-control"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <div className="row mx-auto">
                <button
                  type="submit"
                  class="btn btn-sm btn-secondary btn-block"
                >
                  Submit Form
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
