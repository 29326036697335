import React from "react";
import "./faq.css";

const Faq = () => {
  return (
    <div className="mainContainer">
      <div className="faqContainer w-100 ">
        <h2 className="faqTitle ">FAQ's</h2>
      </div>
      <div className="mt-3 row p-5 font-weight-bold">
        <h2 className="faqHeader">Frequently Asked Questions</h2>
        <div>
          <ul>
            <li>
              {" "}
              <strong>Where and when are your classes? </strong>We hold group
              and corporate CPR classes. Please contact us to discuss
              availability.
            </li>
            <li>
              {" "}
              <strong>How long does a class last?</strong> Training a group to
              competence in Adult/Child/Infant CPR takes approximately 3.5
              hours. First Aid requires an additional 3 hours.
            </li>
            <li>
              {" "}
              <strong>
                What will each student receive at the end of the class?
              </strong>{" "}
              After successful completion of the course, each student receives
              an American Heart Association certification card, valid for two
              years, which indicates that he/she is certified to perform CPR. We
              also provide a quick reference guide to performing CPR, and a
              personal barrier device for mouth-to-mask resuscitation (as
              opposed to mouth-to-mouth).
            </li>
            <li>
              <strong>
                Can you track my CPR expiration date for me, and notify me
                before it expires?
              </strong>{" "}
              Yes, we are happy to track these and notify you 2 months prior to
              expiration and schedule a new class. This is a service that we
              offer free of charge.
            </li>
            <li>
              <strong>When can group classes be scheduled?</strong> Anytime that
              is convenient for you and your group. Many choose early mornings;
              some prefer lunchtime; and others prefer the course to be held
              after work. Just let us know what works best for you.
            </li>
            <li>
              <strong>How much does a class cost?</strong> (The prices below
              apply to individual classes only. Please contact us about group
              classes, as we offer special packages for groups. In addition, we
              are willing to travel to your location to do an on-site training
              for your staff.)
            </li>
          </ul>
          <p>* BLS for Healthcare Provider - <strong>$65 per person</strong></p>
          <p>
            * Heartsaver CPR AED - Please contact us to obtain further details
            regarding this course.
          </p>
          <p>
            {" "}
            * Heartsaver First Aid CPR AED - Please contact us to obtain further
            details regarding this course.
          </p>
          <ul>
            <li>
              <strong>Is there a discount for multiple students?</strong> Yes.
              We offer special perks for employers who hold larger classes and
              individuals who host a CPR party. Please contact us at{" "}
              <a href="tel:916-581-0012" className="telephoneAnchor">
                916-581-0012{" "}
              </a>{" "}
              or e-mail us at{" "}
              <span
                className="emailSpan"
                onClick={() =>
                  (window.location = "mailto:train2sustain@gmail.com")
                }
              >
                train2sustain@gmail.com
              </span>{" "}
              to discuss group rates.
            </li>
            <li>
              <strong>In what languages are classes available? </strong>CPR and
              First Aid classes are available in both English and Hmong.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faq;
