import React from 'react'
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand
} from 'reactstrap';

import './MyNavBar.css';

const MyNavBar = () => {

  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <Navbar expand="md" className='navbarBG pt-3 pb-3'>
        <NavbarBrand href="/">Train 2 Sustain, LLC</NavbarBrand>
        <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/">Welcome</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about-us">About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/faq">FAQs</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/resources">Resources</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div >
  );
}

export default MyNavBar;
